import { useSendInvitationApi } from 'api/counterparty';
import { useState } from 'react';
import { notifType, notify } from 'utils/notifSender';

const emptyForm = {
    first_name: '',
    last_name: '',
    email: '',
    mobile_number: '',
    message: ''
};

export default function useInviteUserModal(apiCaller) {
    const api = useSendInvitationApi(apiCaller);
    const [form, setForm] = useState(emptyForm);
    const [errors, setErrors] = useState({
        first_name: false,
        last_name: false,
        email: false,
        mobile_number: false,
        message: false
    });

    const handleChangeFields = (e) => {
        setForm({ ...form, [e.key]: e.value });
        setErrors({ ...errors, [e.key]: false });
    };

    const submit = (handleClose) => {
        api.mutate(form, {
            onSuccess: () => {
                setForm(emptyForm);
                notify('Invitation sent', notifType.SUCCESS);
                handleClose();
            }
        });
    };

    return {
        errMsg: 'This is required.',
        form,
        errors,
        handleChangeFields,
        submit,
        setErrors
    };
}
