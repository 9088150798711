/* eslint-disable import/no-anonymous-default-export */
export const SHOW_CREATE_ORDER = 'SHOW_CREATE_ORDER';
export const CLOSE_CREATE_ORDER = 'CLOSE_CREATE_ORDER';

const INITIAL_STATE = {
    shouldCreateOrderShown: false,
    preSelectedProductTypeId: '',
    preSelectedProductId: '',
    isBlock: false
};

export const showCreateOrderModal = (productTypeId = '', productId = '', isBlock = false, hasProductAproval = undefined) => {
    return async (dispatch) => {
        dispatch({
            type: SHOW_CREATE_ORDER,
            preSelectedProductTypeId: productTypeId.toString(),
            preSelectedProductId: productId.toString(),
            hasProductAproval,
            isBlock
        });
    };
};

export const closeCreateOrderModal = () => {
    return async (dispatch) => {
        dispatch({
            type: CLOSE_CREATE_ORDER
        });
    };
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_CREATE_ORDER:
            return {
                ...state,
                shouldCreateOrderShown: action.hasProductAproval ?? true,
                preSelectedProductTypeId: action.preSelectedProductTypeId,
                preSelectedProductId: action.preSelectedProductId,
                isBlock: action.isBlock
            };

        case CLOSE_CREATE_ORDER:
            return {
                ...state,
                shouldCreateOrderShown: false
            };

        default:
            return state;
    }
};
