/* eslint-disable react/react-in-jsx-scope */
import Helmet from 'creatella-react-components/lib/Helmet';
import usePageDetails from 'hooks/usePageDetails';
import PropTypes from 'prop-types';
import Favicon from 'react-favicon';

export default function InitPage(props) {
    const page = usePageDetails(props.apiCaller);

    return <>
        <Favicon url={page.icon}/>
        <Helmet
            pathname={props.pathname}
            defaultConfig={props.defaultConfig} />
    </>;
}

InitPage.propTypes = {
    apiCaller: PropTypes.func,
    pathname: PropTypes.string,
    defaultConfig: PropTypes.object
};
