import React from 'react';
import PropTypes from 'prop-types';
import './input.scss';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import NumberInput from 'containers/App/components/Input/NumberInput';

InputForm.propTypes = {
    type: PropTypes.string,
    label: PropTypes.string.isRequired,
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    nolabel: PropTypes.bool,
    classname: PropTypes.string,
    inputClassName: PropTypes.string,
    errMsg: PropTypes.string,
    showError: PropTypes.bool,
    edit: PropTypes.bool,
    disabled: PropTypes.bool,
    rows: PropTypes.string,
    cols: PropTypes.string,
    precision: PropTypes.number,
    canBeNegative: PropTypes.bool
};

function InputForm({ type, label, value, id, name, onChange, required, inputClassName, placeholder, nolabel, classname, errMsg, showError, edit, disabled, cols, rows, precision, canBeNegative }) {
    return (<div className={classname ? classname + ' inputDiv' : 'inputDiv'} id={id + 'DIV'}>
        {edit
            ? <>
                <label className='inputDiv__label'>{label}</label>
                <span className='inputDiv__span'>{value}</span>
            </>
            : (<>{!nolabel && <label className='inputDiv__label'>{label} {required && <span className='inputDiv-label-req'>*</span>}</label>}
                {type === 'phone' ? <PhoneInput
                    country={'za'}
                    containerClass='phone-input'
                    value={value}
                    autoFormat={true}
                    enableSearch={true}
                    onChange={phone => {
                        onChange(phone);
                    }}/>
                    : type === 'number'
                        ? <NumberInput
                            placeholder={placeholder || ''}
                            id={id}
                            name={name}
                            hideArrows={true}
                            onChange={e => onChange(e.target.value)}
                            type={type}
                            className={'inputDiv__input'}
                            disabled={disabled}
                            precision={precision}
                            canBeNegative={canBeNegative}
                            value={value} />
                        : type === 'textarea' ? <textarea
                            rows={rows}
                            cols={cols}
                            id={id}
                            className={`inputDiv__input ${inputClassName}`}
                            disabled={disabled}
                            name={name}
                            value={value} placeholder={placeholder || ''}
                            onChange={e => onChange(e.target.value)}/>
                            : <input id={id} type={type} className={`inputDiv__input ${inputClassName}`}
                                disabled={disabled}
                                name={name} value={value} placeholder={placeholder || ''}
                                onChange={e => onChange(e.target.value)}/>}
                {showError && <span className='inputDiv__error'>{errMsg}</span>}
            </>)
        }
    </div>);
}

export default InputForm;
