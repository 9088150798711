
export class ValueSubscriber {
  #_subscribers = new Set();
  #_value = [];
  subscribe(callback) {
      this.#_subscribers.add(callback);

      return () => {
          this.#_subscribers.delete(callback);
      };
  }

  get value() {
      return this.#_value
          ? this.#_value.length > 1
              ? this.#_value
              : this.#_value[0]
          : undefined;
  }

  setValue(...args) {
      this.#_value = args;
  }

  reset() {
      this.#_value = [];
  }

  // set value(args) {
  //     this.#_value = args;
  // }

  notifySubscribers(...args) {
      this.#_value = args;
      this.#_subscribers.forEach((callback) => callback(args));
  }
}
