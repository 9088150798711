import React, { useRef } from 'react';
import './NoneNegoCheckBox.scss';
import Checkbox from 'screens/Registration/elements/checkBox';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

NoneNegoCheckBox.propTypes = {
    isNoneNego: PropTypes.bool.isRequired,
    setIsNoneNego: PropTypes.func.isRequired
};

function NoneNegoCheckBox(props) {
    const ref = useRef(null);

    return (
        <div className='NoneNegoCheckBox' ref={ref}>
            <Checkbox
                label=''
                nolabel={true}
                placeholder={<Tippy
                    allowHTML={true}
                    placement='top-end'
                    className='pt-tooltip'
                    content={<div>
                        <div>A fixed price order, is a firm order that is not negotiatiable for your counterparty. It is a {'"take it, or leave it"'} trade flow. </div>
                        <br />
                        <div>Either they book you, at the firm price you quoted, or they {"don't"}. No negotiations will follow if they decide they want to counter you with a different price</div>
                    </div>}>

                    <p >Fixed Price Order</p></Tippy>}
                errMsg={''}
                onChange={props.setIsNoneNego}
                value={props.isNoneNego}
                id='NoneNegoCheckBoxID'/>

        </div>
    );
}

export default NoneNegoCheckBox;
