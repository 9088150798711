import { Store } from 'react-notifications-component';
import React from 'react';
import error from 'assets/images/general/error.svg';
import warning from 'assets/images/general/warning.svg';
import success from 'assets/images/general/success.svg';

export const notifType = {
    SUCCESS: 0,
    DANGER: 1,
    WARN: 2
};

export const notifDisposeType = {
    TIMEOUT: 'timeout',
    CLICK: 'click'
};

export function notify(message, type, duration, onClick) {
    let content;

    switch (type) {
        case notifType.SUCCESS:
            content = <div className='notification__message'><img src={success} alt='error' /> {message}</div>;
            break;
        case notifType.DANGER:
            content = <div className='notification__message'><img src={error} alt='error' /> {message}</div>;

            break;
        case notifType.WARN:
            content = <div className='notification__message'><img src={warning} alt='error' /> {message}</div>;

            break;
        default:
            content = <div> {message}</div>;

            break;
    }

    return Store.addNotification({
        content,
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOutRight'],
        dismiss: {
            duration: duration ?? 4000,
            waitForAnimation: true
        },
        onRemoval: onClick
    });
}
