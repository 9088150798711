import { useMutation } from '@tanstack/react-query';
import { TRADING_ELOQUENT as ELOQUENT } from 'config/constants';

/**
 * Get products
 * @param {Object} data
 * @returns {Promise}
 */
export function getProducts(data) {
    return ELOQUENT.Products.index(data);
}

/**
 * Create product
 * @param {Object} data
 * @returns {Promise}
 */
export function createProduct(data, { token }) {
    return ELOQUENT.Products.create(data, token);
}

/**
 * update product
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateProduct(id, data, { token }) {
    return ELOQUENT.Products.update(id, data, token);
}

/**
 * update product status
 * @param {Number} id
 * @param {String} data
 * @returns {Promise}
 */
export function updateProductStatus(id, data, { token }) {
    return ELOQUENT.Products.updateStatus(id, data, token);
}

/**
 * Retrieve single product type record.
 * @param {Number} id
 * @returns {Promise}
 */
export function getProductType(id) {
    return ELOQUENT.ProductTypes.show(id);
}
