import PropTypes from 'prop-types';
import React from 'react';

import './DemoNav.scss';

import { connect } from 'react-redux';
import Button from 'creatella-react-components/lib/Button';
import { ROUTES } from 'config/constants';
import { useHistory } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

DemoNav.propTypes = {
    profile: PropTypes.object.isRequired
};

function DemoNav({ profile }) {
    const history = useHistory();

    return (
        <div className='demo-nav'>
            <div className='demo-nav__text'>
                {`Hi ${profile.name}, this is a demo account for trading and risk management. You can start with a live account once you're familiar with the application.`}
            </div>
            {!profile.trader.company.is_submitted_for_review ? <Button onClick={() => history.push(ROUTES.NEW_REGISTRATION)} className={profile.middle_office.company.registration_step !== null ? 'demo-nav__resume' : 'demo-nav__live'}>
                { profile.middle_office.company.registration_step !== null ? 'RESUME REGISTRATION' : 'GO LIVE'}
            </Button> : !profile.trader.company.is_approved
                ? <Button className='demo-nav__pending'>
                        APPROVAL PENDING
                </Button>
                : <Tippy
                    allowHTML={false}
                    placement='bottom'
                    className='pt-tooltip'
                    content={'Please check your email inbox'}>
                    <div><Button className='demo-nav__approved'>
                        COMPANY APPROVED
                    </Button></div></Tippy>
            }

            {/* <Button className='demo-nav__pending'>
                        APPROVAL PENDING
            </Button> */}

        </div>
    );
}

const mapStateToProps = ({ auth }) => {
    const { profile } = auth;

    return {
        profile
    };
};

export default connect(mapStateToProps, null)(DemoNav);
