import { useEffect } from 'react';
import { ValueSubscriber } from 'utils/ValueSubscriber';

export default function useMainContainerVerticalScrollEvent(event) {
    useEffect(() => MainVerticalScrollService().mainContainerVerticalOnScroll.subscribe(() => {
        if (event) {
            event();
        }
    }), [event]);
}

class EventSrv {
    static #instance = new EventSrv();
    static instance() {
        return EventSrv.#instance;
    }

    // []
    mainContainerVerticalOnScroll = new ValueSubscriber();
    loadMoreVertically() {
        MainVerticalScrollService().mainContainerVerticalOnScroll.notifySubscribers();
    }
}

export function MainVerticalScrollService() {
    return EventSrv.instance();
}
