import * as Sentry from '@sentry/browser';
import { replace } from 'connected-react-router';
import {
    login,
    logout,
    setApiRequestToken,
    unsetApiRequestToken
} from 'api/auth';
import { getAuthedProfile, updateUserProfile } from 'api/user';
import {
    setAuthToken,
    getAuthToken,
    deleteAuthToken
} from './utils/auth/helpers';
import {
    ROUTES,
    ROUTE_REDIRECT_AFTER_LOGIN,
    STORAGEKEY_AFTER_LOGOUT_MSG,
    STORAGEKEY_DEVICE_ID,
    USER_ROLES
} from 'config/constants';
import { updateOrderBookOrders } from './orderbook';
import { notify } from 'utils/notifSender';
import { getTotalProducts } from 'api/limits';
import { getSubdomain } from 'utils/helpers';

const IS_APP_READY = 'IS_APP_READY';

const AUTH_START = 'AUTH_START';
const AUTH_SUCCESS = 'AUTH_SUCCESS';
const AUTH_FAILED_LOGIN = 'AUTH_FAILED_LOGIN';
const AUTH_FAILED_REGISTRATION = 'AUTH_FAILED_REGISTRATION';
const AUTH_LOGOUT_START = 'AUTH_LOGOUT_START';
const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
const HIDE_IMPORTANT_DATA = 'HIDE_IMPORTANT_DATA';

const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START';
const UPDATE_PROFILE = 'UPDATE_PROFILE';
const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
const UPDATE_TRADER_COMPANY = 'UPDATE_TRADER_COMPANY';
const UPDATE_MO_COMPANY = 'UPDATE_MO_COMPANY';

const UPDATE_NON_ACKNOWLEDGED_NEGOTIATION_NUDGES =
    'UPDATE_NON_ACKNOWLEDGED_NEGOTIATION_NUDGES';

const LOCK_USER = 'LOCK_USER';

export const appIsReady = () => ({
    type: IS_APP_READY
});

const authStart = () => ({
    type: AUTH_START
});

const authSuccess = (profile) => ({
    type: AUTH_SUCCESS,
    profile
});

export const loginError = () => ({
    type: AUTH_FAILED_LOGIN
});

const authLogoutStart = () => ({
    type: AUTH_LOGOUT_START
});

const authLogoutSuccess = () => ({
    type: AUTH_LOGOUT_SUCCESS
});

export const hideImportantData = () => ({
    type: HIDE_IMPORTANT_DATA
});

const updateProfileStart = () => ({
    type: UPDATE_PROFILE_START
});

const updateProfile = (profile) => ({
    type: UPDATE_PROFILE,
    profile
});

export const updateProfileSettings = (settings) => ({
    type: UPDATE_SETTINGS,
    settings
});

export const updateTraderCompany = (company) => ({
    type: UPDATE_TRADER_COMPANY,
    company
});

export const updateMOCompany = (company) => ({
    type: UPDATE_MO_COMPANY,
    company
});

export const updateNonAcknowledgedNegotiationsNudges = (nudges, userType) => ({
    type: UPDATE_NON_ACKNOWLEDGED_NEGOTIATION_NUDGES,
    nudges,
    userType
});

export const updateLockUser = (isLocked) => ({
    type: LOCK_USER,
    is_locked: isLocked
});

async function getUserId() {
    if (localStorage[STORAGEKEY_DEVICE_ID]) {
        // localstorage item store before
        const deviceId = localStorage[STORAGEKEY_DEVICE_ID];

        return deviceId;
    } else {
        // localstorage is empty
        // send null to Get device id from BE

        return null;
    }
}

export function handleLogin(
    { email, password, isRemember },
    redirectRoute,
    redirectSearchQuery = null
) {
    return async (dispatch) => {
        dispatch(authStart());
        const deviceId = await getUserId();

        try {
            const loginRes = await login(email, password, deviceId, getSubdomain());

            const token = loginRes.data.access_token;

            setAuthToken(token, isRemember);
            setApiRequestToken(token);

            const { data } = await getAuthedProfile();
            let shouldRedirectToProductsScreen = false;

            if (data.role_type === USER_ROLES.BROKERCOMPANY) {
                // get products count
                const products = await getTotalProducts({ token: null });

                if (
                    products &&
                    products.data.data &&
                    products.data.data.length === 0
                ) {
                    shouldRedirectToProductsScreen = true;
                }
            }

            dispatch(updateOrderBookOrders([]));
            dispatch(authSuccess(data));
            dispatch(
                replace(
                    redirectRoute
                        ? redirectRoute + redirectSearchQuery
                        : ROUTE_REDIRECT_AFTER_LOGIN
                )
            );

            if (
                data.role_type === USER_ROLES.MO &&
                data.middle_office &&
                data.middle_office.company &&
                !data.middle_office.company.onboarding_step
            ) {
                // MO first time logged in
                dispatch(replace(ROUTES.WELCOME_MO));
            }

            if (shouldRedirectToProductsScreen) {
                // BAD first time logged in
                dispatch(replace(ROUTES.MANAGEMENT_BADPRODUCTS));
            }
        } catch (err) {
            if (err && err.response.status) {
                dispatch(handleLogout(true));
                dispatch(loginError());

                switch (Number(err.response.status)) {
                    case 428:
                        localStorage.setItem(
                            STORAGEKEY_DEVICE_ID,
                            err.response.data.device_id
                        );

                        throw err;

                    default:
                        break;
                }
            }

            dispatch(handleLogout(true));
            dispatch(loginError());
        }
    };
}

export function handleLoginWithToken(
    { token, isRemember },
    redirectRoute,
    msgObj
) {
    return async (dispatch) => {
        dispatch(authStart());

        try {
            setAuthToken(token, isRemember);
            setApiRequestToken(token);

            const { data } = await getAuthedProfile();
            let shouldRedirectToProductsScreen = false;

            if (data.role_type === USER_ROLES.BROKERCOMPANY) {
                // get products count
                const products = await getTotalProducts({ token: null });

                if (
                    products &&
                    products.data.data &&
                    products.data.data.length === 0
                ) {
                    shouldRedirectToProductsScreen = true;
                }
            }

            dispatch(updateOrderBookOrders([]));

            if (msgObj) {
                notify(msgObj.msg, msgObj.type);
            }

            dispatch(authSuccess(data));
            dispatch(replace(redirectRoute || ROUTE_REDIRECT_AFTER_LOGIN));

            if (
                data.role_type === USER_ROLES.MO &&
                data.middle_office &&
                data.middle_office.company &&
                !data.middle_office.company.onboarding_step
            ) {
                // MO first time logged in
                dispatch(replace(ROUTES.WELCOME_MO));
            }

            if (shouldRedirectToProductsScreen) {
                // BAD first time logged in
                dispatch(replace(ROUTES.MANAGEMENT_BADPRODUCTS));
            }
        } catch (err) {
            dispatch(handleLogout(true));
            dispatch(loginError());
        }
    };
}

export function handleAutoLogin() {
    return async (dispatch) => {
        try {
            const token = getAuthToken();

            if (token !== null) {
                setApiRequestToken(token);

                const { data } = await getAuthedProfile();

                dispatch(updateOrderBookOrders([]));
                dispatch(authSuccess(data));

                if (
                    data.role_type === USER_ROLES.MO &&
                    data.middle_office &&
                    data.middle_office.company &&
                    !data.middle_office.company.onboarding_step
                ) {
                    // MO first time logged in
                    dispatch(replace(ROUTES.WELCOME_MO));
                }
            }
        } catch (err) {
            dispatch(handleLogout(true));
        } finally {
            dispatch(appIsReady());
        }
    };
}

export function handleLogout(isAutoLogout = false, successMessage = '') {
    return async (dispatch) => {
        dispatch(authLogoutStart());

        const isAuthed = !!getAuthToken();

        deleteAuthToken();

        try {
            if (isAuthed) {
                await logout();
            }
        } catch (err) {
            console.error(err);
            // logout fails when called from unauthed client
            // therefor when called from invalid login
            // silently catch this error
            // in anyway the failing of this api at this point should not cause any issues
        } finally {
            if (successMessage) {
                localStorage.setItem(STORAGEKEY_AFTER_LOGOUT_MSG, successMessage);
            }

            unsetApiRequestToken();

            dispatch(updateOrderBookOrders([]));

            if (isAutoLogout === true) {
                dispatch(authLogoutSuccess());
            } else {
                dispatch(authLogoutSuccess());

                window.location = window.location.origin;
            }
        }
    };
}

export function handleUpdateUserProfile(id, params) {
    return async (dispatch) => {
        dispatch(updateProfileStart());

        try {
            const { data } = await updateUserProfile(id, params);

            dispatch(updateProfile(data));
        } catch (err) {
            dispatch(updateProfile({}));
        }
    };
}

export function handleRefreshUserProfile() {
    return async (dispatch) => {
        dispatch(updateProfileStart());

        try {
            const { data } = await getAuthedProfile();

            dispatch(updateProfile(data));
        } catch (err) {
            dispatch(updateProfile({}));
        }
    };
}

const INITIAL_STATE = {
    isAppReady: false,
    isAuthenticating: false,
    isUpdatingProfile: false,
    isAuthed: false,
    isLoggingOut: false,

    profile: {
        settings: {}
    },
    isLocked: false
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case IS_APP_READY:
            return {
                ...state,
                isAppReady: true
            };

        case AUTH_START:
            return {
                ...state,
                isAuthenticating: true
            };

        case AUTH_FAILED_LOGIN:
        case AUTH_FAILED_REGISTRATION:
            return {
                ...state,
                isAuthenticating: false
            };

        case AUTH_SUCCESS: {
            const { profile } = action;
            const { id, email } = profile;

            Sentry.configureScope((scope) => {
                scope.setUser({ id, email });
            });

            return {
                ...state,
                isAuthenticating: false,
                isAuthed: true,
                profile
            };
        }

        case UPDATE_PROFILE_START:
            return {
                ...state,
                isUpdatingProfile: true
            };

        case UPDATE_PROFILE: {
            const { profile } = action;

            return {
                ...state,
                isUpdatingProfile: false,
                profile: {
                    ...state.profile,
                    ...profile
                }
            };
        }

        case UPDATE_TRADER_COMPANY: {
            const { company } = action;

            return {
                ...state,
                isUpdatingProfile: false,
                profile: {
                    ...state.profile,
                    trader: {
                        ...state.profile.trader,
                        company
                    }
                }
            };
        }

        case UPDATE_MO_COMPANY: {
            const { company } = action;

            return {
                ...state,
                isUpdatingProfile: false,
                profile: {
                    ...state.profile,
                    middle_office: {
                        ...state.profile.middle_office,
                        company
                    }
                }
            };
        }

        case AUTH_LOGOUT_START:
            return {
                ...state,
                isLoggingOut: true
            };

        case AUTH_LOGOUT_SUCCESS:
            return {
                ...INITIAL_STATE,
                isAppReady: true
            };
        case HIDE_IMPORTANT_DATA:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    email: '***',
                    trader: state.profile.is_trader
                        ? {
                            not_acknowledged_negotiation_nudges: [],
                            product_types: [],
                            company: {}
                        }
                        : {},

                    middle_office: state.profile.is_middle_office
                        ? {
                            company: {}
                        }
                        : {},

                    broker: state.profile.is_broker
                        ? {
                            not_acknowledged_negotiation_nudges: [],
                            product_types: []
                        }
                        : {},
                    bad: state.profile.is_bad ? {} : {},
                    settings: {
                        ...state.profile.settings
                    }
                }
            };
        case UPDATE_SETTINGS:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    settings: {
                        ...action.settings
                    }
                }
            };

        case UPDATE_NON_ACKNOWLEDGED_NEGOTIATION_NUDGES:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    [action.userType]: {
                        ...state.profile[action.userType],
                        not_acknowledged_negotiation_nudges: action.nudges
                    }
                }
            };

        case LOCK_USER:
            return {
                ...state,
                isLocked: action.is_locked
            };

        default:
            return state;
    }
};

export default reducer;
