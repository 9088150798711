import { useMutation, useQuery } from '@tanstack/react-query';
import { TRADING_ELOQUENT as ELOQUENT } from 'config/constants';
import { queryClientKeys } from 'config/executors/apiInterceptor';

/**
 * Get counterparties (all)
 * @param {Object} data
 * @returns {Promise}
 */
export function getCounterparties(data, { token }) {
    return ELOQUENT.Counterparties.index(data, token);
}

export const useCounterPartiesApi = (apiCaller) => {
    const query = useQuery({
        queryKey: [queryClientKeys.useCounterPartiesApi],
        queryFn: async () => await apiCaller(getCounterparties, {})
    });

    return { ...query, data: query.data?.data?.data ?? [] };
};

/**
 * Get unassigned counterparties
 * @param {Object} data
 * @returns {Promise}
 */
export function getUnassignedCounterparties(data, { token }) {
    return ELOQUENT.Counterparties.unassigned(data, token);
}

/**
 * add manu counterparties
 * @param {Array} ids
 * @returns {Promise}
 */

export function addManyCounterparties(ids, { token }) {
    return ELOQUENT.Counterparties.storeMany(ids, token);
}

/**
 * activate a Counterparty
 * @param {Number} id
 * @returns {Promise}
 */
export function activateCounterparty(id, { token }) {
    return ELOQUENT.Counterparties.activate(id, token);
}

/**
 * activate a Counterparty
 * @param {Number} id
 * @returns {Promise}
 */
export function deactivateCounterparty(id, { token }) {
    return ELOQUENT.Counterparties.deactivate(id, token);
}

/**
 * activate a Counterparty
 * @param {Number} id
 * @returns {Promise}
 */
export function approveCounterparty(id, { token }) {
    return ELOQUENT.Counterparties.approve(id, token);
}

/**
 * create an external counterparty
 * @param {Object} data
 * @returns {Promise}
 */
export function createExternalCounterparty(data, { token }) {
    return ELOQUENT.Counterparties.storeExternal(data, token);
}

/**
 * Get counterparties of a company
 * @param {Number} companyId
 * @param {Object} data
 * @returns {Promise}
 */
export function getCounterpartiesOf(companyId, data, { token }) {
    return ELOQUENT.Counterparties.of(companyId, data, token);
}

/**
 * Invite a new external counterparty
 * @param {Object} data
 * @returns {Promise}
 */
export function inviteUserToApp(data, { token }) {
    return ELOQUENT.Counterparties.inviteExternal(data, token);
}

export const useSendInvitationApi = (apiCaller) => {
    return useMutation({
        mutationFn: async (nego) => await apiCaller(inviteUserToApp, nego)
    });
};
