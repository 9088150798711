import React, { useRef } from 'react';
import './inviteUser.scss';
import PropTypes from 'prop-types';
import useInviteUserModal from './useInviteUserModal';
import Modal from 'containers/App/components/Modal/Modal';
import Input from 'screens/Registration/elements/input';
import isEmail from 'validator/lib/isEmail';

export default function InviteUserModal({
    isVisible,
    handleClose,
    apiCalller
}) {
    const formMgr = useInviteUserModal(apiCalller);
    const myRef = useRef(null);

    const handleSubmit = e => {
        e.preventDefault();

        const keys = Object.keys(formMgr.errors);

        for (const field of keys) {
            const ref = field + 'Ref';

            if ((formMgr.form[field] === '' || formMgr.form[field] === null) || (field === 'email' && formMgr.form[field] && !isEmail(formMgr.form[field]))) {
                // check if fields are empty

                if (myRef.current[ref]) {
                    myRef.current[ref].scrollIntoView({ behavior: 'smooth', block: 'start' });
                }

                formMgr.setErrors({ ...formMgr.errors, [field]: true });

                return false;
            }
        }

        formMgr.submit(handleClose);
    };

    return <Modal
        isVisible={isVisible}
        onClose={handleClose}
        title={'Invite Company'}
        className=''>
        <form onSubmit={handleSubmit} ref={myRef} className='modal-invite-user' >
            <div className='modal-invite-user__body'>
                <div className='form-row form-row-f'>
                    <div className='form-col'>
                        <Input
                            label='First name'
                            errMsg={formMgr.errMsg}
                            name='first_nameRef'
                            value={formMgr.form.first_name}
                            onChange={e => { formMgr.handleChangeFields({ key: 'first_name', value: e }); }}
                            showError={formMgr.errors.first_name}
                            required={true}/>
                    </div>
                    <div className='form-col'>
                        <Input
                            label='Last name'
                            errMsg={formMgr.errMsg}
                            name='last_nameRef'
                            value={formMgr.form.last_name}
                            onChange={e => { formMgr.handleChangeFields({ key: 'last_name', value: e }); }}
                            showError={formMgr.errors.last_name}
                            required={true}/>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='form-col'>
                        <Input
                            label='Middle Office Email'
                            errMsg={'Invalid email'}
                            name='emailRef'
                            value={formMgr.form.email}
                            onChange={e => { formMgr.handleChangeFields({ key: 'email', value: e }); }}
                            showError={formMgr.errors.email}
                            required={true}/>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='form-col'>
                        {/* <PhoneInput
                            errorMsg={formMgr.errMsg}
                            defaultConutryCode={defaultConutryCode}
                            name='mobile_numberRef'
                            required={true}
                            value={formMgr.form.mobile_number}
                            onChange={e => formMgr.handleChangeFields({ key: 'mobile_number', value: e })}
                            error={formMgr.errors.mobile_number}
                            label='Mobile Number'/> */}
                        <Input
                            label='Mobile Number'
                            errMsg={formMgr.errMsg}
                            name='mobile_numberRef'
                            value={formMgr.form.mobile_number}
                            onChange={e => { formMgr.handleChangeFields({ key: 'mobile_number', value: e }); }}
                            showError={formMgr.errors.mobile_number}
                            required={true}/>
                    </div>
                </div>
                <div className='form-row modal-invite-user__container'>
                    <p>Message <span className='inputDiv-label-req'>*</span></p>
                    <textarea
                        rows={2}
                        name='messageRef'
                        placeholder='Type here'
                        onChangeCapture={e => {
                            formMgr.handleChangeFields({ key: 'message', value: e.target.value });
                        }}
                        value={formMgr.form.message}
                        className='modal-invite-user__container__field-message' />
                </div>

                <div className='form-row modal-invite-user__submit-container'>
                    <button className='modal-invite-user__submit' type='submit'>Send</button>
                </div>
            </div>
        </form>
    </Modal>;
}

InviteUserModal.propTypes = {
    isVisible: PropTypes.bool,
    handleClose: PropTypes.func,
    apiCalller: PropTypes.func
};
