import React from 'react';
import './NotifiicationContents.scss';
import { Link } from 'react-router-dom';
import { ROUTES } from 'config/constants';
import PropTypes from 'prop-types';

export function MONotifOnTraderRequestProductAccess(props) {
    return (
        <div className='MONotifOnTraderRequestProductAccess'>
            Dear {props.moName},
            <br /><br />
            Please note that { props.traderName } has requested access to { props.market } that is being handled by brokerage { props.brokerageName } from {props.country}.
            <br /><br />
            <div className='MONotifOnTraderRequestProductAccess__controls'>
                <Link className='MONotifOnTraderRequestProductAccess__controls__btn MONotifOnTraderRequestProductAccess__controls__confirm' to={{
                    pathname: ROUTES.MANAGEMENT_BROKERAGE
                }}>
                    YES
                </Link>
                <button className='MONotifOnTraderRequestProductAccess__controls__btn MONotifOnTraderRequestProductAccess__controls__cancel'>NO</button>
            </div>
        </div>
    );
}

MONotifOnTraderRequestProductAccess.propTypes = {
    moName: PropTypes.string,
    traderName: PropTypes.string,
    market: PropTypes.string,
    brokerageName: PropTypes.string,
    country: PropTypes.string
};
