import { useGetBrokeragePublicApi } from 'api/brokerage';
import Logo from 'assets/images/header/logo.png';
import { getSubdomain } from 'utils/helpers';
import HELMET_DEFAULT_CONFIG from 'config/executors/i18n-resources/helmet';
import { useLayoutEffect } from 'react';

export default function usePageDetails(apiCaller) {
    const brokerageDetails = useGetBrokeragePublicApi(apiCaller, getSubdomain());

    useLayoutEffect(() => {
        document.title = brokerageDetails.data?.data?.name ?? HELMET_DEFAULT_CONFIG.en.title;
        setTimeout(() => {
            document.title = brokerageDetails.data?.data?.name ?? HELMET_DEFAULT_CONFIG.en.title;
        }, 100);

        return () => {
            document.title = brokerageDetails.data?.data?.name ?? HELMET_DEFAULT_CONFIG.en.title;
            setTimeout(() => {
                document.title = brokerageDetails.data?.data?.name ?? HELMET_DEFAULT_CONFIG.en.title;
            }, 100);
        };
    });

    return {
        ...brokerageDetails,
        icon: brokerageDetails.data?.data?.small_logo ?? '/favicon.ico',
        logo: brokerageDetails.data?.data?.logo ?? Logo,
        title: brokerageDetails.data?.data?.name ?? HELMET_DEFAULT_CONFIG.en.title, // brokerage name
        commercialTerms: brokerageDetails.data?.data?.commercial_tnc ?? ' https://papertrader-production.s3.eu-west-3.amazonaws.com/uploads/Paper-Trader-Platform-SaaS-agreement-Broker-Users-Aug-2021.pdf',
        termsOfCondition: brokerageDetails.data?.data?.condition_of_usage ?? 'https://papertrader-production.s3.eu-west-3.amazonaws.com/uploads/Terms-Conditions-usage-Paper-Trader-Platform-Pte-ltd-Aug-2021.pdf',
        middleOffice: brokerageDetails.data?.data?.middle_office_file ?? ' https://papertrader-production.s3.eu-west-3.amazonaws.com/uploads/Paper-Trader-Platform-SaaS-agreement-Middle-Office-Users-Aug-2021.pdf'
    };
}
